<template>
  <div class="emergencyResponseAlls">
    <div class="emergencyResponseFirst">
      <div class="emergencyResponseFirstImgs">
        <img
          src="https://www.boundaryx.com/wp-content/uploads/2022/05/banner.fed1f492.png"
          alt=""
        />
      </div>
      <div class="emergencyResponseFirst1">
        <h1>应急响应</h1>
        <p class="emergencyResponseFirst1-1">
          针对安全事件从事前准备、检测，到事中处理，再到事后追踪与加固，帮助客户有效减少安全事件所带来的经济损失以及恶劣的社会负面影响。
        </p>
      </div>
    </div>
    <div class="emergencyResponseSecond">
      <h1>服务项</h1>
      <ul>
        <li>
          <img
            src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-2.webp"
            alt=""
          />
          <p class="emergencyResponseSecond1">快速响应</p>
          <p class="emergencyResponseSecond2">Quick response</p>
          <div class="emergencyResponseSecond3"></div>
        </li>
        <li>
          <img
            src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-3.webp"
            alt=""
          />
          <p class="emergencyResponseSecond1">恶意代码清理</p>
          <p class="emergencyResponseSecond2">Malicious code cleanup</p>
          <div class="emergencyResponseSecond3"></div>
        </li>
        <li>
          <img
            src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-2.webp"
            alt=""
          />
          <p class="emergencyResponseSecond1">攻击溯源</p>
          <p class="emergencyResponseSecond2">Attack Tracing</p>
          <div class="emergencyResponseSecond3"></div>
        </li>
        <li>
          <img
            src="https://www.boundaryx.com/wp-content/uploads/2022/05/44-1.webp"
            alt=""
          />
          <p class="emergencyResponseSecond1">安全加固</p>
          <p class="emergencyResponseSecond2">Security Reinforcement</p>
          <div class="emergencyResponseSecond3"></div>
        </li>
      </ul>
    </div>
    <div class="emergencyResponseThird">
      <h1>应急场景</h1>
      <ul>
        <li>
          <div class="emergencyResponseThirdLeft">
            <div class="emergencyResponseThirdLeft1">
              <div class="emergencyResponseThirdLeft1-1"></div>
            </div>
            <div class="emergencyResponseThirdLeft2">
              <div class="emergencyResponseThirdLeft2-1">
                <img
                  src="https://www.boundaryx.com/wp-content/uploads/2022/05/3-2.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="emergencyResponseThirdRight">
            <span class="emergencyResponseThirdRight1">网络攻击事件</span>
            <ul>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">网络扫描事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  黑客利用扫描器对操作系统或应用进行漏洞扫描，尝试发现漏洞。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">漏洞攻击事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  操作系统或应用存在未知漏洞（0day）或已知但未修复的漏洞
                  （1day），遭受黑客攻击。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">暴力破解事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  业务系统遭到暴力破解攻击，黑客尝试获取后台管理员账号的密码。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">
                    拒绝服务攻击事件
                  </div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  服务器遭到拒绝服务攻击，如DDoS或者CC攻击，导致服务器无法提供正常服务。
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div class="newemergencyResponseThirdRight1">
            <span class="emergencyResponseThirdRight1">业务安全事件</span>
            <ul>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">薅羊毛事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  黑客利用业务逻辑缺陷进行欺诈， 获取不正当利益。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">数据泄露事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  隐私数据被非授权人员获取，
                  包括用户账号、密码、银行卡信息、订单数据等。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">权限泄露事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  业务相关权限失控，数据或系统配置被修改。
                </p>
              </li>
            </ul>
          </div>
          <div class="newemergencyResponseThirdLeft1">
            <div class="emergencyResponseThirdLeft1">
              <div class="emergencyResponseThirdLeft1-1"></div>
            </div>
            <div class="emergencyResponseThirdLeft2">
              <div class="emergencyResponseThirdLeft2-1">
                <img
                  src="https://www.boundaryx.com/wp-content/uploads/2022/05/0.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="emergencyResponseThirdLeft">
            <div class="emergencyResponseThirdLeft1">
              <div class="emergencyResponseThirdLeft1-1"></div>
            </div>
            <div class="emergencyResponseThirdLeft2">
              <div class="emergencyResponseThirdLeft2-1">
                <img
                  src="https://www.boundaryx.com/wp-content/uploads/2022/05/9-1.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="emergencyResponseThirdRight">
            <span class="emergencyResponseThirdRight1">恶意程序事件 </span>
            <ul>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">远控木马</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  主机被黑客远程控制作为后门。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">恶意病毒</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  系统被病毒感染，正常功能或数据遭到损坏。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">僵尸网络程序</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  主机被控制，成为了黑客发起DDoS攻击的帮凶“肉鸡”。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">挖矿程序</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  服务器变成了“矿机”，大量资源被消耗， 业务系统无法正常工作。
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div class="newemergencyResponseThirdRight1">
            <span class="emergencyResponseThirdRight1">Web恶意攻击事件 </span>
            <ul>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">WebShell</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  网站被上传了WebShell， 黑客可借此控制主机。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">网页篡改事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  网站页面被篡改，黑客植入炫耀标语。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">网页挂马事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  网站页面被挂马，黑客在页面中植入病毒。
                </p>
              </li>
              <li>
                <div class="emergencyResponseThirdRight2">
                  <div class="emergencyResponseThirdRight2-1"></div>
                  <div class="emergencyResponseThirdRight2-2">网页暗链事件</div>
                </div>
                <p class="emergencyResponseThirdRight3">
                  网站页面被植入暗链， 指向网站类型包括博彩、色情、游戏等。
                </p>
              </li>
            </ul>
          </div>
          <div class="newemergencyResponseThirdLeft1">
            <div class="emergencyResponseThirdLeft1">
              <div class="emergencyResponseThirdLeft1-1"></div>
            </div>
            <div class="emergencyResponseThirdLeft2">
              <div class="emergencyResponseThirdLeft2-1">
                <img
                  src="https://www.boundaryx.com/wp-content/uploads/2022/05/8-1.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.emergencyResponseAlls {
  width: 100%;
  .emergencyResponseFirst {
    position: relative;
    .emergencyResponseFirstImgs {
      position: relative;
      padding-bottom: 28.4%;
      background-image: -webkit-linear-gradient(0deg, #eaf5fe, #dde6fd);
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .emergencyResponseFirst1 {
      width: 35%;
      position: absolute;
      top: 50%;
      left: 19%;
      transform: translate(0, -50%);
      h1 {
        font-size: 35px;
        margin-bottom: 20px;
      }
      .emergencyResponse1-1 {
        font-size: 14px;
        top: 25%;
      }
    }
  }
  .emergencyResponseSecond {
    width: 100%;
    margin-top: 40px;
    h1 {
      text-align: center;
    }
    ul {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      margin-top: 20px;
      li {
        width: 15%;
        background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
        border-radius: 16px;
        padding: 60px 20px;
        text-align: center;
        position: relative;
        overflow: hidden;
        margin-left: 20px;
        img {
          width: 60px;
          height: 60px;
        }
        .emergencyResponseSecond1 {
          font-size: 18px;
          color: #1f2329;
          font-weight: 500;
          margin-top: 20px;
          text-align: center;
        }
        .emergencyResponseSecond2 {
          font-size: 14px;
          color: #51565d;
          line-height: 26px;
          text-align: center;
        }
        .emergencyResponseSecond3 {
          width: 180px;
          height: 180px;
          position: absolute;
          background-color: #89aeff;
          border-radius: 90px;
          bottom: 0;
          right: 0;
          opacity: 0.4;
          filter: blur(53px);
        }
      }
      li:hover {
        border: 1px solid #4c84ff;
      }
    }
  }
  .emergencyResponseThird {
    width: 100%;
    margin-top: 40px;
    h1{
      text-align: center;
    }
    li {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 100px;
      .newemergencyResponseThirdLeft1 {
        width: 30%;
        position: relative;
        .emergencyResponseThirdLeft1 {
          width: 80%;
          position: absolute;
          margin-left: -17px;
          margin-top: -20px;
          .emergencyResponseThirdLeft1-1 {
            position: relative;
            padding-bottom: 61.8%;
            border: 2px solid #4c84ff;
          }
        }
        .emergencyResponseThirdLeft2 {
          width: 87%;
          .emergencyResponseThirdLeft2-1 {
            position: relative;
            padding-bottom: 61.8%;
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
       .emergencyResponseThirdLeft {
        width: 40%;
        position: relative;
        .emergencyResponseThirdLeft1 {
          width: 50%;
          position: absolute;
          margin-left: -17px;
          margin-top: -20px;
          .emergencyResponseThirdLeft1-1 {
            position: relative;
            padding-bottom: 61.8%;
            border: 2px solid #4c84ff;
          }
        }
        .emergencyResponseThirdLeft2 {
          width: 60%;
          .emergencyResponseThirdLeft2-1 {
            position: relative;
            padding-bottom: 61.8%;
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .emergencyResponseThirdRight {
        width: 26.5%;
        .emergencyResponseThirdRight1 {
          font-size: 24px;
          color: #1f2329;
          font-weight: 500;
        }
        li {
          display: flex;
          flex-direction: column;
          margin-top: 26px;
          .emergencyResponseThirdRight2 {
            display: flex;
            .emergencyResponseThirdRight2-1 {
              width: 4px;
              height: 28px;
              background: #4c84ff;
              display: inline-block;
              margin-top: 5px;
            }
            .emergencyResponseThirdRight2-2 {
              margin-left: 8px;
              display: inline-block;
              height: 28px;
              font-size: 24px;
              color: #1f2329;
              font-weight: 500;
            }
            .emergencyResponseThirdRight3 {
              width: 400px!important;
              margin-top: 10px;
              display: block;
              height: 28px;
              font-size: 20px;
              color: #51565d;
              line-height: 22px;
              font-weight: 500;
            }
          }
        }
      }
       .newemergencyResponseThirdRight1 {
        width:35%;
        .emergencyResponseThirdRight1 {
          font-size: 24px;
          color: #1f2329;
          font-weight: 500;
        }
        li {
          display: flex;
          flex-direction: column;
          margin-top: 26px;
          .emergencyResponseThirdRight2 {
            display: flex;
            .emergencyResponseThirdRight2-1 {
              width: 4px;
              height: 28px;
              background: #4c84ff;
              display: inline-block;
              margin-top: 5px;
            }
            .emergencyResponseThirdRight2-2 {
              margin-left: 8px;
              display: inline-block;
              height: 28px;
              font-size: 24px;
              color: #1f2329;
              font-weight: 500;
            }
            .emergencyResponseThirdRight3 {
              width: 300px!important;
              margin-top: 10px;
              display: block;
              height: 28px;
              font-size: 20px;
              color: #51565d;
              line-height: 22px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>